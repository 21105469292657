/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.menu-card {
  @apply flex flex-col justify-center items-center bg-white h-screen font-mono py-40;
}

.center-content {
  @apply flex flex-col justify-center items-center;
}

.selected {
  @apply text-red-600;
}
